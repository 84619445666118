/* roboto-300 - cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local(""), url("~@fonts/roboto/roboto-v20-cyrillic-300.woff2") format("woff2"), url("~@fonts/roboto/roboto-v20-cyrillic-300.woff") format("woff"), url("~@fonts/roboto/roboto-v20-cyrillic-300.ttf") format("truetype"), url("~@fonts/roboto/roboto-v20-cyrillic-300.svg#Roboto") format("svg");
  /* Legacy iOS */
  font-display: swap;
}

/* roboto-regular - cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local(""), url("~@fonts/roboto/roboto-v20-cyrillic-regular.woff2") format("woff2"), url("~@fonts/roboto/roboto-v20-cyrillic-regular.woff") format("woff"), url("~@fonts/roboto/roboto-v20-cyrillic-regular.ttf") format("truetype"), url("~@fonts/roboto/roboto-v20-cyrillic-regular.svg#Roboto") format("svg");
  /* Legacy iOS */
  font-display: swap;
}

/* roboto-500 - cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local(""), url("~@fonts/roboto/roboto-v20-cyrillic-500.woff2") format("woff2"), url("~@fonts/roboto/roboto-v20-cyrillic-500.woff") format("woff"), url("~@fonts/roboto/roboto-v20-cyrillic-500.ttf") format("truetype"), url("~@fonts/roboto/roboto-v20-cyrillic-500.svg#Roboto") format("svg");
  /* Legacy iOS */
  font-display: swap;
}

/* roboto-700 - cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local(""), url("~@fonts/roboto/roboto-v20-cyrillic-700.woff2") format("woff2"), url("~@fonts/roboto/roboto-v20-cyrillic-700.woff") format("woff"), url("~@fonts/roboto/roboto-v20-cyrillic-700.ttf") format("truetype"), url("~@fonts/roboto/roboto-v20-cyrillic-700.svg#Roboto") format("svg");
  /* Legacy iOS */
  font-display: swap;
}

@font-face {
  font-family: 'raleway';
  src: url("~@fonts/raleway/rawline-100.eot");
  src: url("~@fonts/raleway/rawline-100.eot?#iefix") format("embedded-opentype"), url("~@fonts/raleway/rawline-100.woff2") format("woff2"), url("~@fonts/raleway/rawline-100.woff") format("woff"), url("~@fonts/raleway/rawline-100.ttf") format("truetype"), url("~@fonts/raleway/rawline-100.svg") format("svg");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'raleway';
  src: url("~@fonts/raleway/rawline-100i.eot");
  src: url("~@fonts/raleway/rawline-100i.eot?#iefix") format("embedded-opentype"), url("~@fonts/raleway/rawline-100i.woff2") format("woff2"), url("~@fonts/raleway/rawline-100i.woff") format("woff"), url("~@fonts/raleway/rawline-100i.ttf") format("truetype"), url("~@fonts/raleway/rawline-100i.svg") format("svg");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'raleway';
  src: url("~@fonts/raleway/rawline-200.eot");
  src: url("~@fonts/raleway/rawline-200.eot?#iefix") format("embedded-opentype"), url("~@fonts/raleway/rawline-200.woff2") format("woff2"), url("~@fonts/raleway/rawline-200.woff") format("woff"), url("~@fonts/raleway/rawline-200.ttf") format("truetype"), url("~@fonts/raleway/rawline-200.svg") format("svg");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'raleway';
  src: url("~@fonts/raleway/rawline-200i.eot");
  src: url("~@fonts/raleway/rawline-200i.eot?#iefix") format("embedded-opentype"), url("~@fonts/raleway/rawline-200i.woff2") format("woff2"), url("~@fonts/raleway/rawline-200i.woff") format("woff"), url("~@fonts/raleway/rawline-200i.ttf") format("truetype"), url("~@fonts/raleway/rawline-200i.svg") format("svg");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'raleway';
  src: url("~@fonts/raleway/rawline-300-.eot");
  src: url("~@fonts/raleway/rawline-300-.eot?#iefix") format("embedded-opentype"), url("~@fonts/raleway/rawline-300.woff2") format("woff2"), url("~@fonts/raleway/rawline-300.woff") format("woff"), url("~@fonts/raleway/rawline-300.ttf") format("truetype"), url("~@fonts/raleway/rawline-300.svg") format("svg");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'raleway';
  src: url("~@fonts/raleway/rawline-300i.eot");
  src: url("~@fonts/raleway/rawline-300i.eot?#iefix") format("embedded-opentype"), url("~@fonts/raleway/rawline-300i.woff2") format("woff2"), url("~@fonts/raleway/rawline-300i.woff") format("woff"), url("~@fonts/raleway/rawline-300i.ttf") format("truetype"), url("~@fonts/raleway/rawline-300i.svg") format("svg");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'raleway';
  src: url("~@fonts/raleway/rawline-400.eot");
  src: url("~@fonts/raleway/rawline-400.eot?#iefix") format("embedded-opentype"), url("~@fonts/raleway/rawline-400.woff2") format("woff2"), url("~@fonts/raleway/rawline-400.woff") format("woff"), url("~@fonts/raleway/rawline-400.ttf") format("truetype"), url("~@fonts/raleway/rawline-400.svg") format("svg");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'raleway';
  src: url("~@fonts/raleway/rawline-400i.eot");
  src: url("~@fonts/raleway/rawline-400i.eot?#iefix") format("embedded-opentype"), url("~@fonts/raleway/rawline-400i.woff2") format("woff2"), url("~@fonts/raleway/rawline-400i.woff") format("woff"), url("~@fonts/raleway/rawline-400i.ttf") format("truetype"), url("~@fonts/raleway/rawline-400i.svg") format("svg");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'raleway';
  src: url("~@fonts/raleway/rawline-500.eot");
  src: url("~@fonts/raleway/rawline-500.eot?#iefix") format("embedded-opentype"), url("~@fonts/raleway/rawline-500.woff2") format("woff2"), url("~@fonts/raleway/rawline-500.woff") format("woff"), url("~@fonts/raleway/rawline-500.ttf") format("truetype"), url("~@fonts/raleway/rawline-500.svg") format("svg");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'raleway';
  src: url("~@fonts/raleway/rawline-500i.eot");
  src: url("~@fonts/raleway/rawline-500i.eot?#iefix") format("embedded-opentype"), url("~@fonts/raleway/rawline-500i.woff2") format("woff2"), url("~@fonts/raleway/rawline-500i.woff") format("woff"), url("~@fonts/raleway/rawline-500i.ttf") format("truetype"), url("~@fonts/raleway/rawline-500i.svg") format("svg");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'raleway';
  src: url("~@fonts/raleway/rawline-600.eot");
  src: url("~@fonts/raleway/rawline-600.eot?#iefix") format("embedded-opentype"), url("~@fonts/raleway/rawline-600.woff2") format("woff2"), url("~@fonts/raleway/rawline-600.woff") format("woff"), url("~@fonts/raleway/rawline-600.ttf") format("truetype"), url("~@fonts/raleway/rawline-600.svg") format("svg");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'raleway';
  src: url("~@fonts/raleway/rawline-600i.eot");
  src: url("~@fonts/raleway/rawline-600i.eot?#iefix") format("embedded-opentype"), url("~@fonts/raleway/rawline-600i.woff2") format("woff2"), url("~@fonts/raleway/rawline-600i.woff") format("woff"), url("~@fonts/raleway/rawline-600i.ttf") format("truetype"), url("~@fonts/raleway/rawline-600i.svg") format("svg");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'raleway';
  src: url("~@fonts/raleway/rawline-700.eot");
  src: url("~@fonts/raleway/rawline-700.eot?#iefix") format("embedded-opentype"), url("~@fonts/raleway/rawline-700.woff2") format("woff2"), url("~@fonts/raleway/rawline-700.woff") format("woff"), url("~@fonts/raleway/rawline-700.ttf") format("truetype"), url("~@fonts/raleway/rawline-700.svg") format("svg");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'raleway';
  src: url("~@fonts/raleway/rawline-700i.eot");
  src: url("~@fonts/raleway/rawline-700i.eot?#iefix") format("embedded-opentype"), url("~@fonts/raleway/rawline-700i.woff2") format("woff2"), url("~@fonts/raleway/rawline-700i.woff") format("woff"), url("~@fonts/raleway/rawline-700i.ttf") format("truetype"), url("~@fonts/raleway/rawline-700i.svg") format("svg");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'raleway';
  src: url("~@fonts/raleway/rawline-800.eot");
  src: url("~@fonts/raleway/rawline-800.eot?#iefix") format("embedded-opentype"), url("~@fonts/raleway/rawline-800.woff2") format("woff2"), url("~@fonts/raleway/rawline-800.woff") format("woff"), url("~@fonts/raleway/rawline-800.ttf") format("truetype"), url("~@fonts/raleway/rawline-800.svg") format("svg");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'raleway';
  src: url("~@fonts/raleway/rawline-800i.eot");
  src: url("~@fonts/raleway/rawline-800i.eot?#iefix") format("embedded-opentype"), url("~@fonts/raleway/rawline-800i.woff2") format("woff2"), url("~@fonts/raleway/rawline-800i.woff") format("woff"), url("~@fonts/raleway/rawline-800i.ttf") format("truetype"), url("~@fonts/raleway/rawline-800i.svg") format("svg");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'raleway';
  src: url("~@fonts/raleway/rawline-900.eot");
  src: url("~@fonts/raleway/rawline-900.eot?#iefix") format("embedded-opentype"), url("~@fonts/raleway/rawline-900.woff2") format("woff2"), url("~@fonts/raleway/rawline-900.woff") format("woff"), url("~@fonts/raleway/rawline-900.ttf") format("truetype"), url("~@fonts/raleway/rawline-900.svg") format("svg");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'raleway';
  src: url("~@fonts/raleway/rawline-900i.eot");
  src: url("~@fonts/raleway/rawline-900i.eot?#iefix") format("embedded-opentype"), url("~@fonts/raleway/rawline-900i.woff2") format("woff2"), url("~@fonts/raleway/rawline-900i.woff") format("woff"), url("~@fonts/raleway/rawline-900i.ttf") format("truetype"), url("~@fonts/raleway/rawline-900i.svg") format("svg");
  font-weight: 900;
  font-style: italic;
}

/* montserrat-300 - cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: local(""), url("~@fonts/montserrat/montserrat-v15-cyrillic-300.woff2") format("woff2"), url("~@fonts/montserrat/montserrat-v15-cyrillic-300.woff") format("woff"), url("~@fonts/montserrat/montserrat-v15-cyrillic-300.ttf") format("truetype"), url("~@fonts/montserrat/montserrat-v15-cyrillic-300.svg#Montserrat") format("svg");
  /* Legacy iOS */
  font-display: swap;
}

/* montserrat-regular - cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local(""), url("~@fonts/montserrat/montserrat-v15-cyrillic-regular.woff2") format("woff2"), url("~@fonts/montserrat/montserrat-v15-cyrillic-regular.woff") format("woff"), url("~@fonts/montserrat/montserrat-v15-cyrillic-regular.ttf") format("truetype"), url("~@fonts/montserrat/montserrat-v15-cyrillic-regular.svg#Montserrat") format("svg");
  /* Legacy iOS */
  font-display: swap;
}

/* montserrat-500 - cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: local(""), url("~@fonts/montserrat/montserrat-v15-cyrillic-500.woff2") format("woff2"), url("~@fonts/montserrat/montserrat-v15-cyrillic-500.woff") format("woff"), url("~@fonts/montserrat/montserrat-v15-cyrillic-500.ttf") format("truetype"), url("~@fonts/montserrat/montserrat-v15-cyrillic-500.svg#Montserrat") format("svg");
  /* Legacy iOS */
  font-display: swap;
}

/* montserrat-800 - cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  src: local(""), url("~@fonts/montserrat/montserrat-v15-cyrillic-800.woff2") format("woff2"), url("~@fonts/montserrat/montserrat-v15-cyrillic-800.woff") format("woff"), url("~@fonts/montserrat/montserrat-v15-cyrillic-800.ttf") format("truetype"), url("~@fonts/montserrat/montserrat-v15-cyrillic-800.svg#Montserrat") format("svg");
  /* Legacy iOS */
  font-display: swap;
}

/* poppins-100 normal*/
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 100;
  src: local("Poppins"), url("~@fonts/poppins/Poppins-Thin.ttf") format("truetype");
  font-display: swap;
}

/* poppins-100 italic*/
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 100;
  src: local("Poppins"), url("~@fonts/poppins/Poppins-ThinItalic.ttf") format("truetype");
  font-display: swap;
}

/* poppins-200 normal*/
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 200;
  src: local("Poppins"), url("~@fonts/poppins/Poppins-ExtraLight.ttf") format("truetype");
  font-display: swap;
}

/* poppins-200 italic*/
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 200;
  src: local("Poppins"), url("~@fonts/poppins/Poppins-ExtraLightItalic.ttf") format("truetype");
  font-display: swap;
}

/* poppins-300 normal*/
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  src: local("Poppins"), url("~@fonts/poppins/Poppins-Light.ttf") format("truetype");
  font-display: swap;
}

/* poppins-300 italic*/
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 300;
  src: local("Poppins"), url("~@fonts/poppins/Poppins-LightItalic.ttf") format("truetype");
  font-display: swap;
}

/* poppins-400 normal*/
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: local("Poppins"), url("~@fonts/poppins/Poppins-Regular.ttf") format("truetype");
  font-display: swap;
}

/* poppins-400 italic*/
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 400;
  src: local("Poppins"), url("~@fonts/poppins/Poppins-Italic.ttf") format("truetype");
  font-display: swap;
}

/* poppins-500 */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: local("Poppins"), url("~@fonts/poppins/Poppins-Medium.ttf") format("truetype");
  font-display: swap;
}

/* poppins-500 italic*/
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 500;
  src: local("Poppins"), url("~@fonts/poppins/Poppins-MediumItalic.ttf") format("truetype");
  font-display: swap;
}

/* poppins-600 */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: local("Poppins"), url("~@fonts/poppins/Poppins-SemiBold.ttf") format("truetype");
  font-display: swap;
}

/* poppins-600 italic*/
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 600;
  src: local("Poppins"), url("~@fonts/poppins/Poppins-SemiBoldItalic.ttf") format("truetype");
  font-display: swap;
}

/* poppins-700 */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: local("Poppins"), url("~@fonts/poppins/Poppins-Bold.ttf") format("truetype");
  font-display: swap;
}

/* poppins-700 italic*/
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 700;
  src: local("Poppins"), url("~@fonts/poppins/Poppins-BoldItalic.ttf") format("truetype");
  font-display: swap;
}

/* poppins-800 */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  src: local("Poppins"), url("~@fonts/poppins/Poppins-ExtraBold.ttf") format("truetype");
  font-display: swap;
}

/* poppins-800 italic*/
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 800;
  src: local("Poppins"), url("~@fonts/poppins/Poppins-ExtraBoldItalic.ttf") format("truetype");
  font-display: swap;
}

/* poppins-900 */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  src: local("Poppins"), url("~@fonts/poppins/Poppins-Black.ttf") format("truetype");
  font-display: swap;
}

/* manrope-300 */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 300;
  src: local("Manrope"), url("~@fonts/manrope/static/Manrope-Light.ttf") format("truetype");
  font-display: swap;
}

/* manrope-400 */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  src: local("Manrope"), url("~@fonts/manrope/static/Manrope-Regular.ttf") format("truetype");
  font-display: swap;
}

/* manrope-500 */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  src: local("Manrope"), url("~@fonts/manrope/static/Manrope-Medium.ttf") format("truetype");
  font-display: swap;
}

/* manrope-600 */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  src: local("Manrope"), url("~@fonts/manrope/static/Manrope-SemiBold.ttf") format("truetype");
  font-display: swap;
}

/* manrope-700 */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  src: local("Manrope"), url("~@fonts/manrope/static/Manrope-Bold.ttf") format("truetype");
  font-display: swap;
}

/* manrope-800 */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  src: local("Manrope"), url("~@fonts/manrope/static/Manrope-ExtraBold.ttf") format("truetype");
  font-display: swap;
}

* {
  box-sizing: border-box;
  margin: 0;
  border: 0;
}

nav, footer, header, aside {
  display: block;
}

html, body {
  width: 100%;
  background-color: #fff;
  color: #222;
}

h1 {
  font-size: 24px;
  margin: 0;
}

h2 {
  font-size: 22px;
  margin: 0;
}

button {
  cursor: pointer;
}

button:focus {
  outline: 0;
}

svg {
  fill: white;
}

ul, ul ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.ul-with-list-style {
  margin: 0 0 15px 0;
  padding: 0;
  list-style: disc;
}

.ul-with-list-style li {
  margin: 0 0 0 15px;
}

img {
  vertical-align: middle;
  max-width: 100%;
}

*, *:focus, *:hover {
  outline: 0px transparent !important;
}

.d-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.mr-auto {
  margin: 0 auto;
}

.indent {
  text-indent: 30px;
}

.indent-justify-text {
  text-align: justify;
}

.inline-text-link {
  font-size: 16px;
  padding: 0 0 2px 0;
  margin: 0 -4px 0 0;
}

.bold {
  font-weight: bold;
}

.vertical-middle {
  vertical-align: middle !important;
}

.text-gray {
  color: #999;
}

.d-inline {
  display: inline-block;
}

*, *:focus, *:hover {
  outline: none;
}

@media only screen and (max-width: 767.98px) {
  .mobile-no-padding {
    padding: 0;
  }
}

body {
  font-family: 'Roboto', sans-serif;
  font-weight: normal;
  background-color: #ffffff;
  font-size: 16px;
  color: #222;
  line-height: 180%;
}

.header-font, h1, h2, h3 {
  font-family: 'Raleway', 'Roboto', sans-serif;
}

.image-caption {
  text-align: center;
  display: block;
  font-style: italic;
  box-sizing: border-box;
  padding: 15px 0 25px;
  font-size: 16px;
  float: none;
  margin: auto;
  color: #999;
}

.blockquote {
  border-left: 3px solid #c0504d;
  padding-left: 20px;
}

.blockquote div:not(:last-child) {
  padding-bottom: 20px;
}

.loading-cursor {
  cursor: wait;
}

.interactive {
  cursor: pointer;
}

ul.nested-list li ul, ol.nested-list li ul {
  padding-left: 15px;
}

.highlight-bg {
  background: rgba(255, 228, 196, 0.78) !important;
}

.checkbox-big {
  width: 20px;
  height: 20px;
}

.no-margin {
  margin: 0;
}

.list.list--style-inside {
  list-style: inside;
}
